var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _vm._l(_vm.revisions, function (revision, idx) {
          return _c("q-chip", {
            key: idx,
            attrs: {
              removable: "",
              color: revision.color,
              "text-color": "white",
              icon: "memory",
              selected: revision.selected,
              label: revision.revName,
              title: revision.revName,
            },
            on: {
              "update:selected": [
                function ($event) {
                  return _vm.$set(revision, "selected", $event)
                },
                (state) => _vm.selected(state, revision, idx),
              ],
              remove: (state) => _vm.remove(state, _vm.process),
            },
            model: {
              value: revision.check,
              callback: function ($$v) {
                _vm.$set(revision, "check", $$v)
              },
              expression: "revision.check",
            },
          })
        }),
        _c("q-chip", {
          attrs: {
            clickable: "",
            color: "orange",
            "text-color": "white",
            icon: "add",
            label: "버전추가",
            title: "버전추가",
          },
          on: { click: _vm.addProcess },
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        [
          _c(_vm.component, {
            tag: "component",
            attrs: { param: _vm.param, selectedRow: _vm.selectedRow },
            on: {
              "update:selectedRow": function ($event) {
                _vm.selectedRow = $event
              },
              "update:selected-row": function ($event) {
                _vm.selectedRow = $event
              },
            },
          }),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }